import React from 'react';
import Layout from '../../components/layout';

const ProjectPage = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ fontWeight: 'bold' }}>Cyber Security Awareness Campaign</h1>
      <p style={{ marginBottom: '20px' }}> Developed a Finance Department's Email and Malware Awareness and Training Program aimed at strengthening cybersecurity defenses in the finance sector. 
        It combines specialized training on email security and malware risk management with an overview of cyber threats, particularly those most relevant to finance, like phishing, insider threats, and ransomware.</p>
      
      {/* Video Embed */}
      <div style={{ marginBottom: '20px' }}>
        <iframe 
          width="100%" 
          height="400px" 
          src="https://www.youtube.com/embed/urDdwfhfUNk"
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>

      {/* PDF Embed */}
      <div style={{ height: '800px' }}>
        <iframe 
          src="/cyberSecurity-awareness.pdf" 
          width="100%" 
          height="100%" 
          frameBorder="0"
        >
          This browser does not support PDFs. Please download the PDF to view it: <a href="/my-file.pdf">Download PDF</a>
        </iframe>
      </div>
    </div>
  );
};

export default ProjectPage;
